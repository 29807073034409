<template>
  selArtist: {{ selArtist }}
  <div>
    <div style="text-align: center;border: solid black 0px">
      <div id="divArtist" style="font-size: 20px; color: red;font-weight: 600;text-shadow: 4px 3px 4px rgba(0,0,0,.5)">{{ selArtist}}</div>            
      <div id="divAlbum" style="font-size: 18px; color: orange;font-weight: 600;text-shadow: 4px 3px 4px rgba(0,0,0,.5)">Meet The Beatles</div>            
      <div id="divTrackTitle" style="font-size: 16px; color: green;font-weight: 600;margin-bottom: 5px;text-shadow: 4px 3px 4px rgba(0,0,0,.5)">I Saw Her Standing There</div>            
    </div>
    <div style="text-align: center;border: solid black 0px" hidden>            
      <div class="music-player" >      
        <nav>        
          <div class="">
            <span onclick="window.scrollBy(0,-130)" class="material-symbols-outlined" style="border: solid grey 1px;width: 35px;height: 135px;padding-top: 70px;margin-left: 0px">
              chevron_left
              </span>
          </div>
          <div class="">
            <span onclick="window.scrollBy(0,130)" class="material-symbols-outlined" style="border: solid grey 1px;width: 35px;height: 135px;padding-top: 70px;margin-left: 0px">
              chevron_right
              </span>
          </div>
        </nav>
        
        <div id="song" class="divPlayer " style="display: none;margin: 10px auto;justify-content: center;border: solid black 1px">
          <audio id="listenx" controls="controls" display="flex">
                        
          <source src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/Beatles%20-%20I%20Saw%20Her%20Standing%20There.mp3?alt=media&token=a13b0174-dee3-40d6-aeb9-ab183014cbd1" type="audio/mpeg">
          </audio>   
        </div>          

      </div>
    </div>  
    
    <div class="controls w-26rem" style="margin: auto;justify-content: center;border: solid black 0px;" >           
      <div class="w-5rem h-3rem rectangle" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color: rgba(234, 99, 46, 0.985);color: rgb(0, 0, 0);border: solid  rgba(0, 0, 0, 0.89) 1px;text-align: center;">
        <i id="fr" onclick="fastrewind()" class="material-symbols-outlined" style="padding-top: 3px">fast_rewind</i>
      </div>
      <div class="w-5rem h-3rem" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color: rgba(114, 124, 170, 0.89);color: rgb(1, 0, 0);border: solid black 1px;text-align: center;">
        <i id="ctrlIcon" onclick="playPause('https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/Beatles%20-%20I%20Saw%20Her%20Standing%20There.mp3?alt=media&token=a13b0174-dee3-40d6-aeb9-ab183014cbd1')" class="material-symbols-outlined" style="padding-top: 3px">play_circle</i>
      </div>
      <div class="w-5rem h-3rem" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color:rgba(145, 177, 120, 0.996);color: rgb(0, 0, 0);border: solid  rgba(0, 0, 0, 0.89) 1px;text-align: center;">
        <i id="ff" onclick="fastforward()" class="material-symbols-outlined" style="padding-top: 3px">fast_forward</i>
      </div>
    </div>

    <div style="display: flex;margin: auto;justify-content: center;">
      <input type="range" value="0" id="progress" style="height: 30px;border: solid black 0px;display: block;margin: 5px auto;
        background-color: white;" class="w-30rem">                                
      </div>

      <input type="text" value="25%" id="inpVolumeLevel" style="height: 30px;border: solid black 0px;display: none;margin: auto;
        background-color: white;font-size: 20px;text-align: center;" class="w3-input w3-text-purple w-10rem">      

      <input type="range" value="25" id="volume" style="height: 30px;border: solid black 1px;display: none;margin: auto;
        background-color: white;">      
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import HomeView from '@/views/HomeView.vue'
export default {
  components: { HomeView },
  props: ['document'],
  setup() {
    alert("PlayerView")
    const selArtist = ref(HomeView.selArtist)
    alert("selArtist: "+selArtist.value)

    return {selArtist}
  }  
}

</script>

<style>
      *{
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        box-sizing: border-box; 
      }  
      .container {
        position: relative;
        border: solid black 0px; 
        height: 70px; 
        margin-top: 0px;   
        z-index: 1;   
      }
      .music-player {
        margin: auto;
        position: relative;   
        margin-top: 0px;     
        background: #ffffffea;        
        border: solid black 0px;
        z-index: 1;
        width: 50%;      
        background-color: transparent
      }
      nav {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-top: 115px;
      }

      nav .rectangle {
        border-top-left-radius: 40%;
        width: 35px;
        height: 150px;
        line-height: 45px;
        background-color: transparent;
        color: #fffffe;          
        margin-top: -215px;
        border: solid rgb(0, 0, 0) 0px;
        box-shadow: 4px 4px 4px;
        padding-top: 0px;
      }
      nav .search {
        border-top-left-radius: 0%;
        line-height: 0px;
        background-color: transparent;
        color: #fffffe;          
        margin-top: -326px;
        border: solid rgb(0, 0, 0) 0px;
        box-shadow: 0px 0px 0px;
        padding-top: 0px;
      }
      .song-img {
        width: 225px;
        border-radius: 0%;
        border: 1px solid #000;         
      }
      .music-player h1{
        font-size: 20px;
        font-weight: 800;
        color: #333;
        margin-top: 0px;
      }
      .music-player p{
        font-size: 14px;       
        color: #908484;
        margin-top: 60px;
      }
      #progress {
        -webkit-appearance: none;
        width: 100%;
        height: 6px;
        background: #d3cfd3ea;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 0px;
        margin-bottom: 0px;
      }
      #progress::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: #c77cc3fc;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 10px solid #444;
      
      }
      .controls {
        display: flex;
        margin: auto;
        justify-content: space-between;        
        width: 50%;
        margin-top: 20px;
      }
      .controls  div{                
        border-radius: 10%;
        width: 35px;
        background: #ae89ad00;
        cursor: pointer;        
      }      
      .controls  div:nth-child(1){
        transform: scale(1);
        color: #000000;
        height: 35px;
        padding-top: 5px;
        padding-right: 3px;
        box-shadow: 0px 0px 0px rgb(229, 151, 6);
      }
      .controls  div:nth-child(2){
        transform: scale(1);
        background: rgba(211, 101, 200, 0);
        color: #020000;
        height: 35px;
        padding-top: 5px;
        box-shadow: 0px 0px 0px rgb(26, 129, 160);  
      }
      .controls  div:nth-child(3){
        transform: scale(1);
        color: #000000;
        height: 35px;
        padding-top: 5px;
        padding-left: 3px;
        box-shadow: 0px 0px 0px rgb(28, 175, 23);
      }  
</style>