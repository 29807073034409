<template>
  <!--  
  types len: {{ types.length }}<br>
  
  selType: {{ selType }}<br>
  selType2: {{ selType2 }}<br>
  documents: {{ documents.length }}<br>
  sortedData: {{ sortedData.length }}<br>
  selectedType: {{ selectedType }}<br>
  selLanguage: {{ selLanguage }}<br>
  selGenre: {{ selGenre }}<br>
  selSubgenre: {{ selSubgenre }}<br>
  
  selAlbum: {{ selAlbum }}<br>
  selAlbumX: {{ selAlbumX }}<br>
  -->  
  
  <div id="divMain" class="divMain">
  
    <div id="divJukebox" class="divJukebox" @click="showTypes( Event)">
      <img id="imgJukebox" class="imgJukebox" 
        src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/white.jpg?alt=media&token=4328bb07-8fb9-4a6a-86fb-c836912d8e40" alt="">
    </div>
        
    <div @click="onClick"  id="divSearch" class="divSearch hidden">
      <button>Search</button>
    </div>

    <div @click="onClick" id="divPlay" class="divPlay hidden">
      Go
    </div>
    
    <div id="divJookbox" class="divJookbox hidden">
      <img @click="onClick" id="imgJookbox" class="imgJookbox"
        src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/jookbox.png?alt=media&token=5b6174a2-b40b-4523-82ac-ff3adebe8f01" alt="">
    </div>

    <div id="divWaveDancers" class="divWaveDancers hidden">
      <img @click="onClick" id="imgWaveDancers" class="imgWaveDancers"
        src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/wavedancers.jfif?alt=media&token=9c5fde13-4a4b-48c3-b82c-b3973b9fd89b" alt="">
    </div>

    <div id="divControls" class="divControls hidden">
      <img @click="onClick" id="imgvControls" class="imgControls"
        src="https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/grid2.png?alt=media&token=35697429-f6e6-41f5-bf76-7425a0f1fbb1" alt="">
    </div>

    <div id="divDisplay" class="divDisplay hidden">
      <img @click="onClick" id="imgDisplay" class="imgDisplay"
        src="https://media4.giphy.com/media/26tPhMZiL4gjaLZCg/giphy-downsized.gif" alt="">
    </div>
      
    <div id="divNav" class="divNav">     
      
      <div class="divTopRow hidden" id="divTopRow">
        <div v-for="(tr,i) in toprow" :key="i" id="collapseTypes">
          <Button v-if="i==0" id="'divtopRow" class="toprow">        
            Search          
          </Button>        
        </div>  
      </div>
    
      <div class="divNavTypes navs" id="divNavTypes">
        <div v-for="(type,i) in types" :key="i" :id="'divType'+type.Type" @click="chooseNav( i,type.Type )" style="width: 90px;height: 90px;border: solid black 3px;
          border-radius: 4px;margin: 3px;padding-top: 2px;background-image: linear-gradient(0deg, rgba(150, 150, 200, 1) 0%, rgba(0, 0, 0, 1) 100%);color: white">    
          <img :src="rootPath+'/'+type.Type+'/album.jpg'" alt="" style="width: 60px;height: 60px;border: solid black 1px;border-radius: 4px">          
            {{ type.Type }}                    
        </div>          
      </div>
      
      <div class="divNavLanguages navs hidden" id="divNavLanguages">
        <div v-for="(language,i) in types[selectedType].Languages" :key="i" :id="'divLanguage'+language.Language" @click="btnLanguageClicked(i,language.Language)" 
          style="width: 90px;height: 95px;border: solid black 3px;border-radius: 4px;margin: 3px;padding: 2px;
          background-image:  linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(200, 25, 200, 1) 100%);color: white">                            
          <div><img :src="rootPath+'/'+types[selectedType].Type+'/'+language.Language+'/album.jpg'" alt="" style="width: 80px;height: 60px;border: solid black 1px;
            border-radius: 4px">
          </div>          
            {{ language.Language }}          
        </div>            
      </div>
      
      <div class="divNavGenres navs hidden" id="divNavGenres">
        <div v-for="(genre,i) in types[selectedType].Languages[selectedLanguage].Genres" :key="i" :id="'divGenre'+genre.Genre" @click="btnGenreClicked(i,genre.Genre)" style="width: 90px;
          height: 110px;border: solid black 3px;border-radius: 4px;margin: 3px;padding-top: 2px;
            background-image:  linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 200, 1) 100%);color: white">
          <div><img :src="rootPath+'/'+types[selectedType].Type+'/'+types[selectedType].Languages[selectedLanguage].Language+'/'+genre.Genre+'/album.jpg'" alt="" 
            style="width: 80px;height: 80px;border: solid black 1px;border-radius: 4px"></div>
            <div v-if="genre.Genre!='Rock en Espanol'">
              {{ genre.Genre }}
            </div>
            <div v-else>
              Rock
            </div>
        </div>  
      </div>
      
      <div class="divNavSubgenres navs hidden" id="divNavSubgenres">
        <div v-for="(subgenre,i) in types[selectedType].Languages[selectedLanguage].Genres[selectedGenre].Subgenres" :key="i" :id="'divSubgenre'+subgenre.Subgenre" 
          @click="btnSubgenreClicked(i,subgenre.Subgenre)" style="width: 90px;height: 110px;border: solid black 3px;border-radius: 4px;margin: 3px;padding-top: 2px;
            background-image:  linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(200, 100, 100, 1) 100%);color: white">
          <div><img :src="rootPath+'/'+types[selectedType].Type+'/'+types[selectedType].Languages[selectedLanguage].Language+'/'+
            types[selectedType].Languages[selectedLanguage].Genres[selectedGenre].Genre+'/'+subgenre.Subgenre+'/album.jpg'" alt="" style="width: 80px;height: 80px;
            border: solid black 1px;border-radius: 4px"></div>
              {{ subgenre.Subgenre }}              
        </div>  
      </div>
      
      
      <!-- Search -->
      <div style="display: flex;margin: auto;justify-content: center;width: 100%;border: solid black 0px" class="hidden">                
        <textarea id="inpSearch" class="" placeholder="Enter Search Criteria" style="display: block;margin: auto;
          border-radius: 4px;border: solid black 0p"></textarea>
        <div style="display: flex;margin: auto;margin-right: 0px;justify-content: center;flex-wrap: wrap;border: solid 0px;color: transparent" class="hidden">
          <label style="color: purple;border: solid black 0px;padding-top: 0px">Preview</label>
          <input id="chkPreview" type="checkbox" class="w3-check" style="margin-bottom: 14px" />
        </div>        
      </div> 

      <div style="display: flex;margin: auto;justify-content: center;border: solid black 1px;width: 100%" class="hidden">      
        <div id="divAlbums" style="display: flex;margin: auto;border: solid red 0px;flex-wrap: wrap;
          overflow-x: auto;overflow-y: auto;justify-content: left" class="w-full">    
          <div id="divSelectArtists" style="display: flex;margin: 0px auto;justify-content: center;border: solid black 0px;max-height: 90px;overflow-y: auto;
            flex-wrap: wrap;" class="w-full"> 
            <input @click="setSearchInput(document.artist)" setSearchInput v-for="(document, index) in getUniques" :key="index" id="btnArtist" class="w3-btn" type="button" style="display: flex;
              margin: 2px auto;height:40px;border-radius: 4px;border: solid black 0px;text-align: left;font-size: 12;" :value="document.artist">                        
          </div>           
          <div style="display: flex;margin: auto;justify-content: center;border: solid black 0px;overflow: auto;flex-wrap: nowrap;justify-content: left;"
            class="w-full">
            <div v-for="(document, index) in sortedData" :key="index" style="display: flex;margin: auto;justify-content: center;height: 230px;
              border: solid black 0px" class="w-full">
              <input v-if="index==0" id="btnCustomList" @click="customList(index,sortedData)" type="button" value="Play Custom List" class="w3-margin hidden"/>                
              <!--- <router-link @click="ToggleMembers=false,ToggleNavbar=false" id="router" :to="{ name: 'PlaylistDetails', params: { id: playlist.id }}"  style="width: 100%;;border: solid black 10px;"> -->
              <!--- <div  v-bind:id="index" style="background-color: #c7d3e2;" @click="handleClick" class="artist-name">{{ playlist.artist }}</div> -->
              <div v-if="sortedData.length>1 && selAlbum!=document.album" style="border: solid red 0px;justify-content: center;margin-left: 0px;width: 500px">        
                <div style="overflow-x: auto;flex-wrap: wrap;justify-content: center;width: 10 0%">   
                  <div style="border: solid black 0px;margin-bottom: 0px;justify-content: center;width: 100%;overflow-x: auto;">           
                    <div style="display: block;margin: 0px auto;justify-content: center;text-align: center;border: solid black 0px;flex-wrap: wrap" class="w-full">                
                      <div :id="'divArtist'+index" @click="handleClickPL(0,sortedData)" style="font-size: 24px; color: purple;font-weight: 600;width: 100%;height: 20px;
                        margin-bottom: 5px;border: solid black 0px">
                        {{ document.artist }}</div> 
                      <div :id="'divArtist'+index" @click="handleClickPL(0,sortedData)" style="font-size: 16px; color: black;font-weight: 100;width: 100%;height: 20px;
                        margin-bottom: 0px;border: solid black 0px">
                        {{ document.album }}</div>                                                 
                    </div>
                    
                    <div @click="addArtist(document.artist)" class="w-fulll" style="display: flex;margin: 10px auto;scroll-behavior: smooth;justify-content: center;
                      border: solid red 0px">                                                                
                      <img v-if="document.type=='Albums' && selAlbum==document.album" :src="'https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/Albums/'+document.language+'/'+document.genre+'/'+document.subgenre+'/'+document.artist+'/'+document.album+'/album.jpg'" 
                        style="border: solid yellow 0px;border-radius: 8px;box-shadow: 8px 8px 8px rgba(0,0,0,.5);width: 200px;height: 185px" hidden/>
                      <img v-else-if="document.type=='Albums'" :src="'https://storage.cloud.google.com/staging.deploy-template-397316.appspot.com/Albums/'+document.language+'/'+document.genre+'/'+document.subgenre+'/'+document.artist+'/'+document.album+'/album.jpg'" 
                        style="border: solid black 0px;border-radius: 8px;box-shadow: 8px 8px 8px rgba(0,0,0,.5);width: 200px;height: 185px"/>                          
                      <img v-else-if="document.type=='Singles' && selAlbum==document.album" class="thumbnail-mobile" :src="'https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/album.jpg?alt=media&token=1ac3e52b-670f-43db-b009-ee68697533da'" 
                        style="border: solid yellow 0px;border-radius: 8px;box-shadow: 8px 8px 8px rgba(0,0,0,.5);width: 200px;height: 185px" hidden/>                                                
                      <img v-else-if="document.type=='Singles'" class="thumbnail-mobile" :src="'https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/album.jpg?alt=media&token=1ac3e52b-670f-43db-b009-ee68697533da'" 
                        style="border: solid black 0px;border-radius: 8px;box-shadow: 8px 8px 8px rgba(0,0,0,.5);width: 200px;height: 185px;margin-top: 20px"/>                                
                    </div>                                                                            
                  </div>             
                </div>                                         
              </div>
              
              <div v-else-if="sortedData.length==1" id="divFilter" style="display: flex;border: solid black 0px;border-radius: 8px; flex-wrap: wrap;flex-direction: row;overflow-x: auto;
                overflow-y: auto;margin-left: 0px;width: 99%;height: 250px">  
              
                <div id="divShowOtherAlbums" style="display: flex;margin: auto;text-align: center;" hidden>
                  <button type="button" style="text-align: center;font-size: 14px;width: 100%" class="btn btn-info">Show Other Albums</button >        
                </div>
                
                <div id="divSongs" class="" style="display: block;border: solid black 0px;width: 100%">                       
                  <div v-for="(album,i) in sortedData" :key="i" class="album w-full" style="display: flex;margin: auto;
                    justify-content: left;flex-direction: row;border: solid green 0px">    

                    <div class="w-full" style="display: flex;margin: auto;justify-content: left;flex-wrap: wrap;border: solid black 0px">
                      <div v-for="(song,j) in album.songs" :key="j" selSong="ALoversConcerto.mp3" style="display: flex;margin-left: 0px;width: 100%;
                        justify-content: left;flex-direction: row;border: solid black 0px"> 
                        <div style="border: solid black 0px;margin-bottom: 0px">
                          <input v-if="song.Song[0].LoveIt" :id="'chkSong'+i+'-'+j" type="checkbox" class="w3-check" style="margin-top: 0px" checked/>
                          <input v-else :id="'chkSong'+i+'-'+j" type="checkbox" class="w3-check" style="margin-bottom: 5px" />                              
                        </div>
                        <div style="padding-top: 2px;margin: 1px;border: solid 0px" class="w-full">
                          <button :id="'btn'+i+'-'+j" v-if="song.Song[0].DisplayTitle.split('-').length==4 && j==0" @click="handleClick(j,album)" style="margin-top: 1px;color: black;background-color: #ffffff;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                            width: 100%;border-radius: 3px;font-weight: 700;border: solid black 0px;margin-left: 0px;padding-top: 5px;padding-left: 5px;height: 30px" class="w3-btn btn-success play btnMPL" hidden>
                            {{ song.Song[0].DisplayTitle.split('-')[1].split('(')[0] }}
                          </button>
                          <button :id="'btn'+i+'-'+j" v-if="song.Song[0].DisplayTitle.split('-').length==3" @click="handleClick(j,album)" style="margin-top: 1px;color: black;background-color: #ffffff;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                            width: 100%;border-radius: 3px;font-weight: 700;border: solid black 0px;margin-left: 0px;padding-top: 5px;padding-left: 5px;height: 30px" class="w3-btn btn-success play btnMPL">
                            {{ song.Song[0].DisplayTitle.split('-')[1].split('(')[0] }}
                          </button>
                          
                          <button :id="'btn'+i+'-'+j" v-else-if="song.Song[0].DisplayTitle.split('-').length==2 && j==0" @click="handleClick(j,album)" style="margin-top: 1px;color: black;background-color: #ffffff;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                            width: 100%;border-radius: 3px;font-weight: 700;border: solid black 0px;margin-left: 0px;padding-top: 5px;padding-left: 5px;height: 30px" class="w3-btn btn-success play btnMPL">
                            {{ song.Song[0].DisplayTitle.split('-')[1].split('(')[0] }}
                          </button>

                          <button :id="'btn'+i+'-'+j" v-else-if="song.Song[0].DisplayTitle.split('-').length==2" @click="handleClick(j,album)" style="margin-top: 1px;color: black;background-color: #ffffff;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                            width: 100%;border-radius: 3px;font-weight: 700;border: solid black 0px;margin-left: 0px;padding-top: 5px;padding-left: 5px;height: 30px" class="w3-btn btn-success play btnMPL">
                            {{ song.Song[0].DisplayTitle.split('-')[1].split('(')[0] }}
                          </button>

                          <button :id="'btn'+i+'-'+j" v-else-if="i==j && i==0" @click="handleClick(j,album)" style="margin-top: 1px;background-color: #ffffff;color: black;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                            width: 100%;border-radius: 3px;font-weight: 700;border: solid black 0px;margin-left: 0px;padding-top: 5px;padding-left: 5px;height: 30px" class="btn btn-success play btnMPL">
                            {{ song.Song[0].DisplayTitle.split('(')[0].split(',')[0] }}
                          </button>

                          <button :id="'btn'+i+'-'+j" v-else @click="handleClick(j,album)" style="margin-top: 1px;color: black;background-color: #ffffff;font-size: 12px;text-align: left;box-shadow: 3px 3px 4px rgba(0,0,0,.5);
                            width: 100%;border-radius: 3px;font-weight: 700;border: solid black 0px;margin-left: 0px;padding-top: 5px;padding-left: 5px;height: 30px" class="btn btn-success play btnMPL">
                            {{ song.Song[0].DisplayTitle.split('(')[0].split(',')[0] }}
                          </button>

                        </div>  
                        <input id="'addDJ'+j" type="button" class="btn w-3rem" @click="addToDJ(j,selType2,selLanguage,selGenre,selSubgenre,selArtist,selAlbum,song,
                        album.Songs)" style="margin-left: 5px;margin-top: 4px;border-radius: 6px;height: 30px;font-weight: 600;border: solid black 0px;color: white;background-color: dodgerblue;
                          padding-top: 3px" value="DJ" />                  
                      </div>           
                    </div>
                  </div>                                      
                </div>
              </div>
            </div>       
          </div>
        </div>
      </div> 
      <div id="divPlayer" class="divPlayer hidden" style="display: flex;margin: 10px auto;justify-content: center;border: solid black 0px;z-index: 2;">
        <audio class="" 
          id="listen"            
          controls="controls"
          display="flex"
          preload="none"
        ></audio>
      </div>  
            
      <div id="divControls" class="controls w-full hidden" style="display: n;margin: auto;justify-content: center;border: solid black 0px;margin-top: 5px;margin-bottom: 0px">           
        <div class="w-5rem h-3rem" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color: red;color: rgb(0, 0, 0);
          border: solid rgba(0, 0, 0, 0.89) 1px;text-align: center;">
          <i id="ff" @click="startOver(sortedData)" class="material-symbols-outlined" style="padding-top: 3px">arrow_back</i>
        </div>
        <div class="w-5rem h-3rem rectangle" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color: lightpink;color: rgb(0, 0, 0);border: solid  rgba(0, 0, 0, 0.89) 1px;text-align: center;">
          <i id="fr" onclick="fastrewind()" class="material-symbols-outlined" style="padding-top: 3px">fast_rewind</i>
        </div>
        <div id="inpPlay" class="w-5rem h-3rem" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color: rgba(114, 124, 170, 0.89);color: rgb(1, 0, 0);border: solid black 0px;text-align: center;">
          <!-- <i id="ctrlIcon"  @click="handleClickPL(0,sortedData)" class="material-symbols-outlined" style="padding-top: 3px">play_circle</i> -->
          <i id="ctrlIcon" @click="play()" class="material-symbols-outlined" style="padding-top: 3px">play_circle</i>
        </div>
        <div class="w-5rem h-3rem" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color: lightgreen;color: rgb(0, 0, 0);border: solid  rgba(0, 0, 0, 0.89) 1px;text-align: center;">
          <i id="ff" onclick="fastforward()" class="material-symbols-outlined" style="padding-top: 7px">fast_forward</i>
        </div>
        <div class="w-5rem h-3rem" style="margin-left: 5px;margin-right: 5px;box-shadow: black 3px 3px 3px;background-color: green;color: rgb(0, 0, 0);border: solid  rgba(0, 0, 0, 0.89) 1px;text-align: center;">
          <i id="ff" @click="nextSong(0,sortedData)" class="material-symbols-outlined" style="padding-top: 7px">arrow_forward</i>
        </div>
      </div>

      <div id="divVolume" style="border: solid 0px" hidden>
        <div style="display: flex;margin: auto;justify-content: center;flex-wrap: wrap;margin-top: 10px;margin-bottom: 10px;width: 100%">        
          <div style="display: inline-flex;margin: auto;flex-wrap: nowrap;width: 100%;border: solid black 0px;">          
            <input type="range" value="0" id="progress" style="display: block;margin: auto;height: 30px;border: solid black 0px;margin-top: 0px;;margin-bottom: 0px; 
            margin-left: 15px;background-color: white;" class="w-18rem">                                
            <input type="text" value="25%" id="inpVolumeLevel" style="height: 30px;border: solid black 0px;display: flex;margin: auto;
              background-color: white;font-size: 16px;text-align: center;" class="w3-input w3-text-purple w-5rem" >      
          </div>        
        </div>  

        <div style="display: flex;margin: auto;justify-content: center;flex-wrap: wrap;margin-top: 10px;margin-bottom: 10px;width: 100%">        
          <div style="display: inline-flex;margin: auto;flex-wrap: nowrap;width: 100%;border: solid black 0px;">          
            <input type="range" value="0" id="" style="display: block;margin: auto;height: 30px;border: solid black 0px;margin-top: 0px;;margin-bottom: 0px; 
            margin-left: 15px;background-color: white;" class="w-18rem">                                
            <input type="text" value="25%" id="inpVolumeLevel" style="height: 20px;border: solid black 0px;display: flex;margin: auto;
              background-color: white;font-size: 16px;text-align: center;width: 55px" class="w3-input w3-text-purple" >      
          </div>        
        </div>
      </div>      
    </div>
    
    <div v-if="isMobile()" style="display: flex;margin: auto;border: solid black 0px;border-radius: 8px;padding: 5px;position: absolute;top: 680px;
      left: 5px;width: 95%;flex-wrap: wrap;">

      <div style="display: flex;margin: auto;flex-wrap: wrap;justify-content: center;width: 100%;border: solid black 2px;z-index: 1;margin-bottom: 10px;
        background-color: lightgrey;padding: 10px;height: 160px;border-radius: 8px;" class="hidden">            
        <div style="display: inline-flex;width: 100%;border: solid black 0px;justify-content: center;margin-top: 0px;margin-bottom: 10px">
          <input @click="moveElement('up',1)" type="button" value="Up" class="btn" style="border: solid black 1px">
        </div>
        <div style="display: inline-flex;width: 100%;justify-content: center;">
          <div style="display: inline-flex;width: 100%;margin-bottom: 10px;">
            <input @click="moveElement('left',1)" type="button" value="Left" class="btn" style="border: solid black 1px;margin-right: 10px">
            <input @click="moveElement('right',1)" type="button" value="Right" class="btn" style="border: solid black 1px">
          </div>
        </div>
        <div style="display: inline-flex;width: 100%;border: solid black 0px;justify-content: center;">
          <input @click="moveElement('down',1)" type="button" value="Down" class="btn" style="border: solid black 1px">
        </div>
      </div>
      <div style="background-color: lightgrey;z-index: 1;width: 100%;height: 160px;border: solid black 2px;border-radius: 8px;padding: 5px" class="hidden">
        <table>
          <tr style="height: 30px;border:solid red 0px;justify-content: center;">
            <th style="height: 30px;border:solid black 0px;justify-content: center;">
              <label style="display: flex;margin: auto;justify-content: center;width: 50px;border:solid black 0px;text-align: center;">Position</label>
            </th>
            <th>
              <label style="display: flex;margin: auto;justify-content: center;width: 50px;text-align: center">Width</label>
            </th>
            <th>
              <label style="display: flex;margin: auto;justify-content: center;width: 50px;text-align: center">Height</label>
            </th>
          </tr>
          <tr>
            <td style="justify-content: center;">
              <input id="chkPosition" name="chkProps" style="width: 75px" class="w3-radio" type="radio" checked>
            </td>
            <td style="justify-content: center;">
              <input id="chkWidth" name="chkProps" style="width: 75px" class="w3-radio" type="radio">
            </td>
            <td style="display: flex;margin: auto;justify-content: center;">
              <input id="chkHeight" name="chkProps" style="width: 75px" class="w3-radio" type="radio">
            </td>
          </tr>
        </table>
        <table style="margin-top: 20px">
          <tr style="height: 30px;border:solid red 0px;justify-content: center;">
            <th style="height: 30px;border:solid black 0px;justify-content: center;">
              <label style="display: flex;margin: auto;justify-content: center;width: 50px;border:solid black 0px;text-align: center;">1 px</label>
            </th>
            <th>
              <label style="display: flex;margin: auto;justify-content: center;width: 50px;border:solid black 0px;text-align: center;">10 px</label>
            </th>
            <th>
              <label style="display: flex;margin: auto;justify-content: center;width: 50px;border:solid black 0px;text-align: center;">100 px</label>
            </th>
          </tr>
          <tr>
            <td style="justify-content: center;">
              <input id="chkPosition" name="chkPX" style="width: 75px" class="w3-radio" type="radio" checked>
            </td>
            <td style="justify-content: center;">
              <input id="chkWidth" name="chkPX" style="width: 75px" class="w3-radio" type="radio">
            </td>
            <td style="display: flex;margin: auto;justify-content: center;">
              <input id="chkHeight" name="chkPX" style="width: 75px" class="w3-radio" type="radio">
            </td>
          </tr>
        </table>
      </div>
    </div>    
  </div>
</template>
<script>
  import { ref, onMounted } from 'vue';
  import { rootPath  } from '../assets/json/rootPath.json'
  import { Jukebox } from '../assets/json/jukebox.json'
  import { Empty } from '../assets/json/jukebox.json'
  import { Toprow } from '../assets/jbLinks.json'
  import { Types } from '../assets/jbLinks.json'
  import getUser from '@/composables/getUser'
  import { Members } from '../assets/json/members.json'
  import { useToast } from "primevue/usetoast";

  import useStorage from '@/composables/useStorage'
  import useCollection from '@/composables/useCollection'
  import getCollection from '@/composables/getCollection'
  import getDocument from '@/composables/getDocument'
  
  import { timestamp } from '@/firebase/config'

  import { SPLGenres } from '../assets/splLinks.json'
  import { EPLGenres } from '../assets/eplLinks.json'
  
  import { Youtubeplaylists } from '../assets/json/youtubeplaylists.json'
  import { Youtubes } from '../assets/json/youtubes.json'
  import { YouTubeLinks } from '../assets/youtubelinks.json'
  import { Playlist } from '../assets/json/playlists.json'
  import { Requests } from '../assets/json/dj.json'
  import DJ from '/djJSON.js'
  import Alpha from '../assets/json/alpha.json'
  import PlayerView from '@/components/PlayerView.vue'
  import '../style.css';
  import Multiselect from 'vue-multiselect'

  import { getFirestore, collection, getDocs, doc } from "firebase/firestore";

  import { ColorInputWithoutInstance } from "tinycolor2";

  //import { query, orderBy, limit } from "firebase/firestore";  

  //import { doc } from "firebase/firestore";
  export default {    
    name: 'App',
    props: ['id'],
    mounted: function() {
      //$("#divColors").toggle();     
      //inpDesign.click();    
      //inpDesign.click();     
      //$("#startButton").click()
      //var playList = "Alejandro Fernandez,Banda Machos,Bronco,Grupo Niche,Javier Solis,Juan Carlos Coronel,Juan Gabriel,KLAX,Las Jiguerillas,Linda Ronstadt,Los Angeles Negros,Los Bondadosos,"
      inpSearch.focus();
      //playlistCount =0
      //artistList.options.add(new Option(("Mario "+playlistCount,playList)));
      //$("#inpSearch").focus();               
    },    
    methods: { 
      designMode() {    
          //alert(this.isPlayMobile())    
              
          if(inpDesign.value == "Design" && this.isPlayMobile())
          {
              imgStreamBG.style.width = "56%"
              divMainx.style.width = "56%"
              divPower.style.width = "56%"
              inpDesign.value = "Deploy"                
              ////console.log("Design")
              inpDesign.style.top = "0px"
              inpDesign.style.display = "none"    
          } else {
              imgStreamBG.style.width = "60%"
              divMainx.style.width = "60%"
              divPower.style.width = "60%"
              inpDesign.value = "Design"
              ////console.log("Deploy")       
              inpDesign.style.top = "0px"                                          
          }
          
      },
      isPlayMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              //alert("Playlist Is Mobile = true");
              return true
          } else {
              //alert("Playlist Is Mobile = false");
              return false
          }
      }, 
      isMobile() {
          if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              //console.log("Is Mobile = true");
              this.isMobileFlag == true
              return true
          } else {
              //console.log("Is Mobile = false");
              this.isMobileFlag == false
              return false
          }
      },          
      onChange(e) {
        //console.log("id: " + e);
      },
      onFocus(e) {
        alert("onFocus: " + e);           
      },
      onBlur(e) {
        //console.log("onBlur: " + e);            
      },      
      onClick(e) {
        //alert("onFocus: " + $(e.target).attr('id'))
        this.curEl = $(e.target).attr('id')
      },     
    },
    data() {
      return { 
        Design: true,
        Artist: '',
        Title: '',
        searchBy: 'Artist',
        sortbyData: 'artist',       
        value: [],
        options: [
          {name: 'Vue.js', language: 'JavaScript'},
          {name: 'Adonis', language: 'JavaScript'},
          {name: 'Rails', language: 'Ruby'},
          {name: 'Sinatra', language: 'Ruby'},
          {name: 'Laravel', language: 'PHP'},
          {name: 'Phoenix', language: 'Elixir'}
        ],
      };
    },
    computed: { 
      filteredSearchStartsWith(q) {
        alert(q)
      },
      getUniques() {
        if(this.getData) {
          let tempArray = [];
          for (let item of this.sortedData) {
            (tempItem => {
              if (!(tempItem.length > 0 && tempItem.find(x => x.artist === item.artist))) tempArray.push(item);
            })(tempArray.filter(x => x.artist === item.artist))
          }
          return tempArray;
        }
      },      
      getData() {  
        //alert("x")
        if(this.selArtists) {
          //alert("0")
          //this.selArtists = "Elvis Presley,Michael Jackson"
          return this.documents.filter(
            (documents) =>               
            documents.artist == this.selArtists.split(',')[0] ||
            documents.artist == this.selArtists.split(',')[1] ||
            documents.artist == this.selArtists.split(',')[2] ||
            documents.artist == this.selArtists.split(',')[3] ||
            documents.artist == this.selArtists.split(',')[4] ||
            documents.artist == this.selArtists.split(',')[5] ||
            documents.artist == this.selArtists.split(',')[6] ||
            documents.artist == this.selArtists.split(',')[7] ||
            documents.artist == this.selArtists.split(',')[8] ||
            documents.artist == this.selArtists.split(',')[9] ||

            documents.artist == this.selArtists.split(',')[10] ||
            documents.artist == this.selArtists.split(',')[11] ||
            documents.artist == this.selArtists.split(',')[12] ||
            documents.artist == this.selArtists.split(',')[13] ||
            documents.artist == this.selArtists.split(',')[14] ||
            documents.artist == this.selArtists.split(',')[15] ||
            documents.artist == this.selArtists.split(',')[16] ||
            documents.artist == this.selArtists.split(',')[17] ||
            documents.artist == this.selArtists.split(',')[18] ||
            documents.artist == this.selArtists.split(',')[19] ||

            documents.artist == this.selArtists.split(',')[20] ||
            documents.artist == this.selArtists.split(',')[21] ||
            documents.artist == this.selArtists.split(',')[22] ||
            documents.artist == this.selArtists.split(',')[23] ||
            documents.artist == this.selArtists.split(',')[24] ||
            documents.artist == this.selArtists.split(',')[25] ||
            documents.artist == this.selArtists.split(',')[26] ||
            documents.artist == this.selArtists.split(',')[27] ||
            documents.artist == this.selArtists.split(',')[28] ||
            documents.artist == this.selArtists.split(',')[29] ||

            documents.artist == this.selArtists.split(',')[30] ||
            documents.artist == this.selArtists.split(',')[31] ||
            documents.artist == this.selArtists.split(',')[32] ||
            documents.artist == this.selArtists.split(',')[33] ||
            documents.artist == this.selArtists.split(',')[34] ||
            documents.artist == this.selArtists.split(',')[35] ||
            documents.artist == this.selArtists.split(',')[36] ||
            documents.artist == this.selArtists.split(',')[37] ||
            documents.artist == this.selArtists.split(',')[38] ||
            documents.artist == this.selArtists.split(',')[39] ||

            documents.artist == this.selArtists.split(',')[40] ||
            documents.artist == this.selArtists.split(',')[41] ||
            documents.artist == this.selArtists.split(',')[42] ||
            documents.artist == this.selArtists.split(',')[43] ||
            documents.artist == this.selArtists.split(',')[44] ||
            documents.artist == this.selArtists.split(',')[45] ||
            documents.artist == this.selArtists.split(',')[46] ||
            documents.artist == this.selArtists.split(',')[47] ||
            documents.artist == this.selArtists.split(',')[48] ||
            documents.artist == this.selArtists.split(',')[49]
          )
        } else if(this.documents!=null) {
          //alert("00")    
          if(this.selType2 && !this.selLanguage) {
            //alert("1")    
            //this.selArtists = "Juan Gabriel,Luis Miguel,Pepe Aguilar,Vicente Fernandez"              
            //alert("this.selArtists.length: "+this.selArtists.length)          

            if(this.selArtists.length==0) {
              //alert(inpSearch.value.toLowerCase())
              //alert(inpSearch.value.toLowerCase().split(',')[0])
              //alert(inpSearch.value.toLowerCase().split(',')[1])
              //alert(inpSearch.value.length)
              
              //alert("this.output.textContent: "+output.textContent)
              //alert("inpSearch.value: "+inpSearch.value)
              //alert("inpSearch.value: ")
              if(inpSearch.value=='' || inpSearch.value.indexOf('album') != -1) {
                //alert("this.selType2: "+this.selType2)
                //alert("1: inpSearch.value: '"+inpSearch.value+"'")
                return this.documents.filter(
                  (documents) =>        
                  documents.type == this.selType2
                )
              } else {
                //inpSearch.value = output.value
                //alert("2: inpSearch.value: '"+inpSearch.value+"'")
                if(inpSearch.value.slice(-1)==',') {{}
                  inpSearch.value = inpSearch.value.substring(0, inpSearch.value.length - 1);
                }                
                //alert("2:2: inpSearch.value: '"+inpSearch.value+"'")
                return this.documents.filter(
                  (documents) =>            
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[0]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[1]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[2]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[3]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[4]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[5]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[6]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[7]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[8]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[9]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[10]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[11]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[12]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[13]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[14]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[15]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[16]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[17]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[18]) ||
                  documents.artist.toLowerCase().startsWith(inpSearch.value.toLowerCase().split(',')[19])
                )
              }
            } else {
              return this.documents.filter(
                (documents) =>            
                documents.artist == this.selArtists.split(',')[0] ||
                documents.artist == this.selArtists.split(',')[1] ||
                documents.artist == this.selArtists.split(',')[2] ||
                documents.artist == this.selArtists.split(',')[3] ||
                documents.artist == this.selArtists.split(',')[4] ||
                documents.artist == this.selArtists.split(',')[5] ||
                documents.artist == this.selArtists.split(',')[6] ||
                documents.artist == this.selArtists.split(',')[7] ||
                documents.artist == this.selArtists.split(',')[8] ||
                documents.artist == this.selArtists.split(',')[9]
                //|| documents.artist.toLowerCase().includes(inpSearch.value.toLowerCase()))
              )
            }              
          } else if(this.selType2 && this.selLanguage && !this.selGenre) {
            //alert("2")              
            //if(this.selType2 == '') {
              //this.selType2="Albums"
            //}
            return this.documents.filter(
              (documents) =>  
              documents.type == this.selType2 &&
              documents.language == this.selLanguage
            )
          } else if(this.selType2 && this.selLanguage && this.selGenre && !this.selSubgenre) {
            //alert("3")
            return this.documents.filter(
              (documents) =>  
              documents.type == this.selType2 &&
              documents.language == this.selLanguage &&
              documents.genre == this.selGenre
            )
          } else if(this.selType2 && this.selLanguage && this.selGenre && this.selSubgenre && !this.selArtist) {
            //alert("4")
            return this.documents.filter(
              (documents) =>  
              documents.type == this.selType2 &&
              documents.language == this.selLanguage &&
              documents.genre == this.selGenre &&
              documents.subgenre == this.selSubgenre
            )
          } else if(this.selType2 && this.selLanguage && this.selGenre && this.selSubgenre && this.selArtist) {
            //alert("5")
            return this.documents.filter(
              (documents) =>  
              documents.type == this.selType2 &&
              documents.language == this.selLanguage &&
              documents.genre == this.selGenre &&
              documents.subgenre == this.selSubgenre && 
              documents.artist == this.selArtist 
            )
          } else if(this.selType2 && this.selLanguage && this.selGenre && this.selSubgenre && this.selArtist && this.selAlbum) {
            //alert("6")
            return this.documents.filter(
              (documents) =>  
              documents.type == this.selType2 &&
              documents.language == this.selLanguage &&
              documents.genre == this.selGenre &&
              documents.subgenre == this.selSubgenre && 
              documents.artist == this.selArtist &&
              documents.album == this.selAlbum
            )
          } else {
            //alert("7")
            return this.documents.filter(
              (documents) =>  
              documents.type == this.selType2
            )
          }
        } 
      },
      sortedData() {
        if(this.documents!=null) {
          return this.getData.sort((a, b) =>
            a[this.sortbyData].localeCompare(b[this.sortbyData]),                    
          );
        }
      },
      filteredType() {
        if(this.documents!=null) {
          return this.documents.filter(
            (documents) =>            
            documents.type == this.selType2
          );
        }
      },    
      filteredLanguage() {
        if(this.documents!=null) {
          return this.documents.filter(
            (documents) =>            
            documents.language == this.selLanguage 
          );
        }
      },        
      filteredGenre() {
        if(this.documents!=null) {
          return this.documents.filter(
            (documents) =>            
            documents.genre == this.selGenre
          );
        }
      },        
      filteredSubgenre() {
        if(this.documents!=null) {
          return this.documents.filter(
            (documents) =>            
            documents.subgenre == this.selSubgenre
          );
        }
      },
      filteredLanguageStartsWith() {
        alert("filteredLanguageStartsWith")
        return this.documents.filter(
            (documents) =>            
            documents.language == this.selLanguage 
            //&&
            //jukebox.Artists.toLowerCase()  
            //.startsWith(this.Artist.trim().toLowerCase())                           
          );
      },             
      filteredArtistsStartsWith() {
        //alert("filteredArtistsStartsWith")
        //alert(this.selType2)
        if(this.selType2!='') {
          var lookup = {};
          var items = Jukebox.filter(
            (documents) =>            
            documents.Languages == this.selLanguage 
            && documents.Genres == this.selGenre           
            && documents.Subgenres == this.selSubgenre 
            //&& jukebox.Artists == this.selArtist 
            //&& jukebox.Albums == this.selAlbum
            //&& jukebox.Songs[0].Title == '01_Adoro_Bronco_Adios Disco 1.mp3'  
            && jukebox.Artists.toLowerCase()  
            .startsWith(this.Artist.trim().toLowerCase())                           
          );
          var result = [];

          for (var item, i = 0; item = items[i++];) {
            var artist = item.Artists;

            if (!(artist in lookup)) {
              lookup[artist] = 1;
              //alert("lookup: " + JSON.stringify(lookup))
              result.push(artist);
            
            }
            else {
              //alert("artist: " + artist)
            }
          }

          //alert(JSON.stringify(result))
          return result                         
        }
      },
      filteredArtistsContains() {
        //alert("filteredArtistsContains")
        //alert(this.selType2)
        if(this.selType2!='') {
          //alert("1")
          var lookup = {};
          var items = Jukebox.filter(
            (jukebox) =>            
            jukebox.Languages == this.selLanguage 
            && jukebox.Genres == this.selGenre           
            && jukebox.Subgenres == this.selSubgenre 
            //&& jukebox.Artists == this.selArtist 
            //&& jukebox.Albums == this.selAlbum
            //&& jukebox.Songs[0].Title == '01_Adoro_Bronco_Adios Disco 1.mp3'  
            && jukebox.Artists.toLowerCase()  
            .includes(this.Artist.trim().toLowerCase())                           
          );
          var result = [];

          for (var item, i = 0; item = items[i++];) {
            var artist = item.Artists;

            if (!(artist in lookup)) {
              lookup[artist] = 1;
              //alert("lookup: " + JSON.stringify(lookup))
              result.push(artist);
              
            }
            else {
              //alert("artist: " + artist)
            }
          }

          //alert(JSON.stringify(result))
          return result                             
        } else {
          //alert("2")
          //alert("1: "+ this.jukebox)
          //return this.jukebox = []
          //alert("2: "+ this.jukebox)
            
        }   
      },
      filteredYouTubePlayListContains(genres) {
        //alert("filteredYouTubePlayListContains")   
        //alert(JSON.stringify(genres.genre))
        for( var i = 0; i < genres.length; i++){ 
          //alert(genres[i].genre)
          return this.youtubeplaylistContains(
            (youtubeplaylistContains) =>                                     
            youtubeplaylistContains.genre.toLowerCase() == genres[i].genre.toLowerCase() &&
              youtubes.artist 
              .includes(this.Artist.toLowerCase().trim().toLowerCase())  
          );
        }                                     
      },
      filteredYouTubes() {
        //alert("filteredYouTubes")   
        //alert("youtubes.genres: " + this.youtubes.genres[0].genre) 
        //alert("0: this.selLanguage: " + this.selLanguage)             
        //alert("0: this.selGenre: " + this.selGenre)  
        //this.selGenre = 'Cuco'
        //alert("2: this.selLanguage: " + this.selLanguage) 
        if(this.selType2!='') {  
          //alert("this.selType2: " + this.selType2)             
          if(this.selGenre=="") {
            //alert("1: this.selLanguage: " + this.selLanguage) 
            return this.youtubes.filter(
              (youtubes) =>                                     
              youtubes.language == this.selLanguage &&
              youtubes.artist 
              .includes(this.Artist.toLowerCase().trim().toLowerCase())     
            );                             
          } else if(this.selLanguage!="Members" && this.selGenre!="") {              
            //alert("2: this.selLanguage: " + this.selLanguage) 
            return this.youtubes.filter(
              (youtubes) =>                                     
              youtubes.language == this.selLanguage &&
              youtubes.genre.includes(this.selGenre) &&     
              youtubes.artist.trim().toLowerCase().includes(this.Artist.trim().toLowerCase()) 
            );
          }  else if(this.selLanguage=="Members" && this.selGenre!="") {                             
            if(this.selSearchBy == 'Artist') {
              //alert("3: this.selSearchBy: Artist")
              return this.youtubes.filter(
                (youtubes) =>                                     
                youtubes.genre.includes(this.selGenre) &&  
                youtubes.artist.trim().toLowerCase().includes(this.Artist.trim().toLowerCase())                                          
              );                          
            } else if(this.selSearchBy == 'Title') {
              //alert("3: this.selSearchBy: Title")
              return this.youtubes.filter(
                (youtubes) =>                                     
                youtubes.genre.includes(this.selGenre) &&  
                youtubes.title.trim().toLowerCase().includes(this.Title.trim().toLowerCase()) 
                                        
              );   
            }
          }            
          else {
            //alert("4: this.selLanguage: " + this.selLanguage)  
            if(this.selSearchBy == 'Artist') {  
              return this.youtubes.filter(
                (youtubes) =>                                                    
                youtubes.artist.trim().toLowerCase().includes(this.Artist.trim().toLowerCase())     
              );     
            } else if(this.selSearchBy == 'Title') {
              return this.youtubes.filter(
                (youtubes) =>                                                    
                youtubes.title.trim().toLowerCase().includes(this.Title.trim().toLowerCase())     
              );
            }     
          }
        }
      },
      filteredAlbumsStartsWith() {              
        //alert("filteredAlbumsStartsWith")                
        //alert(this.selType2)
        //if(this.selType2!='') {
          //if(this.selLanguage != '' && this.selGenre != '' && this.selSubgenre != '' && this.selArtist != '' && this.selArtist != null ) {  
            //alert(this.selType2+"/"+this.selLanguage+"/"+this.selGenre+"/"+this.selSubgenre+"/"+this.selArtist)          
            //alert(this.selGenre)
            //alert(this.selSubgenre)
            //alert(this.selArtist )

            return this.jukebox.filter(
              (jukebox) =>            
              jukebox.Types == this.selType2
              && jukebox.Languages == this.selLanguage 
              && jukebox.Genres == this.selGenre           
              && jukebox.Subgenres == this.selSubgenre 
              && jukebox.Artists == this.selArtist 
              &&
              jukebox.Albums.toLowerCase()  
              .includes(this.Artist.trim().toLowerCase())                           
            );            
          //}
        //}                           
      },
      filteredSongsStartsWith() {   
          
        //alert("filteredSongsStartsWith")                
        //alert(this.selType2)
        //alert(this.selLanguage)          
            //alert(this.selGenre)
            //alert(this.selSubgenre)
            //alert(this.selArtist)
            //alert(this.selAlbum)
            //alert("this.selSong: " + this.selSong)
        
        if(this.selType2=='Albums') {
          if(this.selLanguage != '' && this.selGenre != '' && this.selSubgenre != '' && this.selArtist != '' && this.selAlbumX != '') {  
            

              return this.documents.filter(
                (jukebox) =>            
                jukebox.language == this.selLanguage 
                && jukebox.genre == this.selGenre           
                && jukebox.subgenre == this.selSubgenre 
                && jukebox.artist == this.selArtist 
                && jukebox.album == this.selAlbumX
                //&& jukebox.Songs[0].Title == '01_Adoro_Bronco_Adios Disco 1.mp3'  
                //&&
                //jukebox.Artists.toLowerCase()  
                //.startsWith(this.Artist.trim().toLowerCase())                           
              );            
          }
        } else if(this.selType2=='Singles') {
          if(this.selLanguage != '' && this.selGenre != '' && this.selSubgenre != '' && this.selArtist != '' && this.selArtist != null && this.selAlbumX != '' && this.selAlbumX != null ) {  
            

              return this.documents.filter(
                (jukebox) =>            
                jukebox.language == this.selLanguage 
                && jukebox.genre == this.selGenre           
                && jukebox.subgenre == this.selSubgenre 
                && jukebox.artist == this.selArtist 
                && jukebox.album == this.selAlbumX
                //&& jukebox.Songs[0].Title == '01_Adoro_Bronco_Adios Disco 1.mp3'  
                //&&
                //jukebox.Artists.toLowerCase()  
                //.startsWith(this.Artist.trim().toLowerCase())                           
              );            
          }
        }                           
      },
      filteredbyPlaylist() {
        //alert("filteredbyGenrePlaylist")
        //alert(this.selType2)
        if(this.playlistSearch!='') {
          return this.jukebox.filter(
            (jukebox) =>            
            //jukebox.Types == this.selType2
            jukebox.Languages == "Spanish"
            
            && jukebox.Genres.includes("Mexicana")
            //&& (
            //   //jukebox.Subgenres.includes("Ranchera") ||
            //   jukebox.Subgenres.includes("Bolero") 
            //  //jukebox.Subgenres.includes("Balada")
            //)         
            
            //|| jukebox.Subgenres == "Cumbia"         
            //&& jukebox.Subgenres == this.selSubgenre              
            && jukebox.Artists == 'Vicente Fernandez'
            &&
            jukebox.Subgenres
            .includes(this.playlistSearch)                           
          );
        }
      },
      filteredMember() {
        //alert("this.selectedMember: " + this.selectedMember)
        return this.members = []
        //  (members) =>            
        //  members.id == this.selectedMember                    
        //);
      },  
      filteredGenrePlaylist() {
        //alert("filteredGenrePlaylist")
        //alert(this.selType2)
        
        return this.masterPlaylist.filter(
          (masterPlaylist) =>            
          masterPlaylist.Genres == this.selGenre
        );          
      },
      filteredGenrePlaylistSongs() {
        //alert("filteredGenrePlaylistSongs")
        //alert(this.selPlaylist)
        
        return this.masterPlaylist.filter(
          (masterPlaylist) =>            
          masterPlaylist.Playlist == this.selPlaylist
        );          
      },                
    },
    el: '#app',
    props: ['document'],    
    setup() {   
      
      const pureColor = ref<ColorInputWithoutInstance>("orange");
      const gradientColor = ref("linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)");

      let documents = []
      //documents.forEach((doc) => {
      //    console.log(documents.id, documents.data());
      //});
      //console.log(documents.data())
      //if(documents!= null) {
        //console.log(documents)
        //console.log(documents['rawValue'])
      //}
      let newDocs = ref()

      let selData = ref([])
      const db = getFirestore();

      async function getCollectionData() {
        const collectionRef = collection(db, "test");
        const snapshot = await getDocs(collectionRef);

        snapshot.forEach((doc) => {          
          documents.push(doc.data())                            
        });                     
        //console.log("doc.data: "+doc.data);
        //selData.value = documents
        console.log("documents.length: "+documents.length);
        //console.log("selData.value: "+selData.value);
      }
      getCollectionData()         

      const jukebox = ref(Jukebox) 
      //console.log("jukebox: "+JSON.stringify(jukebox))
      const empty = ref(Empty)
      const toprow = ref(Toprow)
      const types = ref(Types)
      let selSearchByString = ref('StartsWith')    
      
      let selectedMember = ref(0)
      let selectedType = ref(0)
      let selectedLanguage = ref(0)
      let selectedGenre = ref(0)
      let selectedSubgenre = ref(0)
      let selectedArtist = ref(0)
      let selectedAlbum = ref(0)
      let selectedSong = ref(0)
      let selectedPlaylist = ref(0)     
      
      let selYTLanguage = ref("")
      let selYTGenre = ref("")

      let currentUser = ref([0])
      let curUser = ref('')
      let curEl = ref('divSearch')

      let curArtist = ref('')
      
      let prevTime = ref(4)
      let active 

      const selMember = ref('')
      const selType = ref('')
      const selType2 = ref('')
      //alert(Jukebox[0].Languages)
      const selLanguage = ref('')
      const selGenre = ref('')
      const selSubgenre = ref('')
      const selArtist = ref('')
      const selArtists = ref('')
      const selAlbum = ref('')
      const selAlbumX = ref('')
      const selSong = ref('')

      let typeVisited = 0
      let langVisited = 0
      let genreVisited = 0
      let subgenreVisited = 0
      let artistVisited = 0
      let albumVisited = 0
      let songVisited = 0  
      
      let curLevel = ref("Type")
      let playlistSearch = ref('')
      let selSearchBy = ref('Artist')

      let scanCount = 0
      let letter = ref('')
      let column = ref('')
      let bgColor = ref()
      let isMobileFlag = ref(false)
      let previewFlag = ref(false)
      
      var curTime = 0
      var curTrack = ''

      const Test = () => {        
        alert("Test")
      }

      const toggleDivNav = () => {    
        //alert("toggleDivNav")        
        $("#showTypes").removeClass("divNavFull")      
        $("#divNav").removeClass("divNavFull")
        $("#divNav").addClass("divNav")

      }

      function randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min);
      }
        
      const handleClickPL = (j, documents) => {    
        //alert("j: "+j)
        //alert("documents: "+documents)
        var playList = [] 
        var playedList = [] 
        var curRandomCombo = ''
        var albumInt = null
        var songInt = null

        var type = ''
        var language = ''
        var genre = ''
        var subgenre = ''
        var artist = ''
        var album = ''
        var song = '' 
        var displayTitle = '' 

        var player = document.getElementById("listen")
        var progress = document.getElementById("progress")
        let playerartist = document.getElementById("playerArtist");
        let ctrlIcon = document.getElementById("ctrlIcon");    

        var trackPath = ''
        //alert("trackPath: "+trackPath)
        for(var i=0;i<documents.length;i++) {
          for(var j=0;j<documents[i].songs.length;j++) {
            albumInt = i.toString()
            songInt = j.toString()

            type = documents[i].type
            language = documents[i].language
            genre = documents[i].genre
            subgenre = documents[i].subgenre
            artist = documents[i].artist
            album = documents[i].album
            song = documents[i].songs[j].Song[0].Title
            displayTitle = documents[i].songs[j].Song[0].DisplayTitle

            if(type=="Albums") {
              trackPath = rootPath+"/"+type+"/"+language+"/"+genre+"/"+subgenre+"/"+artist+"/"+album+"/"+song+"\\"+displayTitle
            } else {
              trackPath = rootPath+"/"+type+"/"+language+"/"+genre+"/"+subgenre+"/"+album+"/"+artist+"/"+song+"\\"+displayTitle
            }
              


            curRandomCombo = albumInt+"-"+songInt
            playList.push(curRandomCombo+"|"+trackPath)
            //alert("playList: "+playList[i])
          }  

        }
        //alert("trackPath: "+trackPath)
        /*
        for(var i=0;i<documents.length;i++) {
          for(var j=0;j<documents[i].songs.length;j++) {
            albumInt = i.toString()
            songInt = j.toString()
            curRandomCombo = albumInt+"-"+songInt
            playList.push(curRandomCombo)
          }  
        }
        */

        //console.log("Before: Play list"+JSON.stringify(playList))
        
        let randomNumber = randomIntFromInterval(0,(playList.length-1));                
        
        //alert("curAlbum: "+curBtn)

        var curCombo = playList[randomNumber].split('|')[0] //album-song          

        var typeName = playList[randomNumber].split('|')[1].split('/')[4]
        var languageName = playList[randomNumber].split('|')[1].split('/')[5]          
        var genreName = playList[randomNumber].split('|')[1].split('/')[6]
        var subgenreName = playList[randomNumber].split('|')[1].split('/')[7]

        if(typeName == 'Albums') {
          var artistName = playList[randomNumber].split('|')[1].split('/')[8]
          var albumName = playList[randomNumber].split('|')[1].split('/')[9]
        } else {
          var artistName = playList[randomNumber].split('|')[1].split('/')[9]
          var albumName = playList[randomNumber].split('|')[1].split('/')[8]
        }
        
        var songName = playList[randomNumber].split('|')[1].split('/')[10]

        //var removeLatinCHR = removeLatinAccents("CARLOS Y JOSE - ESLABÓN POR ESLABÓN.MP3");
        //alert(removeLatinCHR)

        var songDisplay = playList[randomNumber].split('\\')[1]

        selType2.value = typeName
        selLanguage.value = languageName
        selGenre.value = genreName
        selSubgenre.value = subgenreName

        //var removeLatinCHR = removeLatinAccents("CARLOS Y JOSE - ESLABÓN POR ESLABÓN.MP3");
        //alert(removeLatinCHR)

        console.log("1: artistName: "+artistName)
        console.log("1: albumName: "+albumName)

        //if(typeName.value == 'Albums') {
          selArtist.value = removeLatinAccents(artistName)
          selAlbum.value = removeLatinAccents(albumName)

        console.log("2: artistName: "+artistName)
        console.log("2: albumName: "+albumName)
        //} else {
          //selArtist.value = artistName
          //selAlbum.value = albumName
        //}


        selSong.value = songDisplay

        //alert("selAlbum: "+selAlbum.value)
        var curAlbum = curCombo.split('-')[0] //album
        var trackPath = playList[randomNumber].split('|')[1].split('\\')[0] //song

        var curBtn = "btn"+curCombo
        var curchk = "chkSong"+curCombo

        playedList.push(curCombo)
        //console.log("Played list"+JSON.stringify(playedList))
        playList.splice(randomNumber, 1);
        //console.log("After: Play list"+JSON.stringify(playList))

        $('.btnMPL').each(function(i, obj) {
          $(obj).css("color","black")
        });

        $("#"+curBtn).css("color","white")
        $("#"+curBtn).css("background-color","green")
        $("#"+curchk).prop('checked', true);

        //let filePath = documents[albumInt].filePath
        //if(filePath.indexOf("Spanish")==0) {
        //  filePath = "/Albums/"+filePath
        //}
                
        //let songTitle= documents[curAlbum].songs[curSong].Song[0].Title
        //let trackPath = (rootpath + filePath + songTitle)
        
        let songLen = 0
        player.addEventListener('loadedmetadata', () => {
          const duration = player.duration; 
          //alert("duration: "+duration)    
          songLen = duration
        });
                
        let endtime = 8    // stop at 5 seconds      

        if ($('#chkPreview').is(":checked")) {
          previewFlag = true
        } else {
          previewFlag = false
        }
        //alert("previewFlag: "+previewFlag)                
        //alert("trackPath: "+trackPath)alert("trackPath: "+trackPath)
        player.setAttribute( 'src', trackPath)
        //player.setAttribute( 'src', "https://firebasestorage.googleapis.com/v0/b/muso-ninjas-80a45.appspot.com/o/Beatles%20-%20I%20Saw%20Her%20Standing%20There.mp3?alt=media&token=a13b0174-dee3-40d6-aeb9-ab183014cbd1")        

        //alert("trackPath: "+trackPath)
        //progress.value = player.currentTime;        

        //player.currentTime =curTime

        player.currentTime = 0
        if(player.play()) {    
          //alert("ctrlIcon.innerHTML : " + ctrlIcon.innerHTML);
              
          setInterval(() => {
                      
            progress.max = songLen;
            progress.value = player.currentTime;
            playerartist.value = progress.value;
            curTime = player.currentTime
            //console.log("curTime): "+curTime)
          }, 100);

                      
          //alert("ctrlIcon.innerHTML : " + ctrlIcon.innerHTML);
          if(ctrlIcon.innerHTML == "play_circle") {
            //alert("Play Song");
            ctrlIcon.innerHTML = "pause_circle";                        
          } else {
            //alert("Pause Song");
            //mat.classList.remove("mat");
            //record.classList.remove("record");  
            player.pause();
            ctrlIcon.innerHTML = "play_circle";
            if (!player.paused) {
                //video.pause();
                player.pause();
            }
            
          }
                              
        }                        
                  
        if(previewFlag == true) {
          player.addEventListener("timeupdate", function() { 
            if (this.currentTime >= endtime) {                       
              if(playList.length>0) {
                player.currentTime = 0;           

                //console.log("Before: Play list"+JSON.stringify(playList))
                randomNumber = randomIntFromInterval(0,(playList.length-1));        
                
                var curCombo = playList[randomNumber].split('|')[0] //album-song
                
                var typeName = playList[randomNumber].split('|')[1].split('/')[4]
                var languageName = playList[randomNumber].split('|')[1].split('/')[5]          
                var genreName = playList[randomNumber].split('|')[1].split('/')[6]
                var subgenreName = playList[randomNumber].split('|')[1].split('/')[7]
                
                if(typeName == 'Albums') {
                  var artistName = playList[randomNumber].split('|')[1].split('/')[8]
                  var albumName = playList[randomNumber].split('|')[1].split('/')[9]
                } else {
                  var artistName = playList[randomNumber].split('|')[1].split('/')[9]
                  var albumName = playList[randomNumber].split('|')[1].split('/')[8]
                }

                //var songName = playList[randomNumber].split('|')[1].split('/')[10]
                var songDisplay = playList[randomNumber].split('\\')[1]

                selType2.value = typeName
                selLanguage.value = languageName
                selGenre.value = genreName
                selSubgenre.value = subgenreName

                if(typeName.value == 'Albums') {
                  selArtist.value = artistName
                  selAlbum.value = albumName
                } else {
                  selArtist.value = artistName
                  selAlbum.value = albumName
                }

                selSong.value = songDisplay
                //alert("curCombo: "+curCombo)
                var curAlbum = curCombo.split('-')[0] //album
                var trackPath = playList[randomNumber].split('|')[1].split('\\')[0] //song

                curBtn = "btn"+curCombo
                curchk = "chkSong"+curCombo

                playedList.push(curCombo)
                //console.log("Played list"+JSON.stringify(playedList))
                playList.splice(randomNumber, 1);
                //console.log("After: Play list"+JSON.stringify(playList))

                //alert("play: "+curBtn)
                $('.btnMPL').each(function(i, obj) {
                  $(obj).css("color","black")
                });

                $("#"+curBtn).css("color","white")
                $("#"+curBtn).css("background-color","green")
                $("#"+curchk).prop('checked', true);

                //filePath = documents[curAlbum].filePath
                //if(filePath.indexOf("Spanish")==0) {
                //  filePath = "/Albums/"+filePath
                //}
                
                //songTitle= documents[curAlbum].songs[curSong].Song[0].Title
                //trackPath = (rootpath + filePath + songTitle)
                console.log("trackPath: "+trackPath)
                player.setAttribute( 'src', trackPath)
                player.pause()   
                player.play()                
              } else {
                listen.pause() 
                console.log("Done!")
              }
            }                                     
            //console.log("Preview Play mode")
            //console.log("listen.currentTime: " + listen.currentTime)          
            //console.log(trackPath)   
          }, false);                                         
        } else {
          //listen.currentTime = 200;           

                
          //console.log("Before: Play list"+JSON.stringify(playList))
          player.addEventListener("ended", function() {
            randomNumber = randomIntFromInterval(0,(playList.length-1));        
            
            var curCombo = playList[randomNumber].split('|')[0] //album-song
            
            var typeName = playList[randomNumber].split('|')[1].split('/')[4]
            var languageName = playList[randomNumber].split('|')[1].split('/')[5]          
            var genreName = playList[randomNumber].split('|')[1].split('/')[6]
            var subgenreName = playList[randomNumber].split('|')[1].split('/')[7]
            
            if(typeName == 'Albums') {
              var artistName = playList[randomNumber].split('|')[1].split('/')[8]
              var albumName = playList[randomNumber].split('|')[1].split('/')[9]
            } else {
              var artistName = playList[randomNumber].split('|')[1].split('/')[9]
              var albumName = playList[randomNumber].split('|')[1].split('/')[8]
            }

            //var songName = playList[randomNumber].split('|')[1].split('/')[10]
            var songDisplay = playList[randomNumber].split('\\')[1]

            selType2.value = typeName
            selLanguage.value = languageName
            selGenre.value = genreName
            selSubgenre.value = subgenreName

            if(typeName.value == 'Albums') {
              selArtist.value = artistName
              selAlbum.value = albumName
            } else {
              selArtist.value = artistName
              selAlbum.value = albumName
            }

            selSong.value = songDisplay
            //alert("curCombo: "+curCombo)
            var curAlbum = curCombo.split('-')[0] //album
            var trackPath = playList[randomNumber].split('|')[1].split('\\')[0] //song

            curBtn = "btn"+curCombo
            curchk = "chkSong"+curCombo

            playedList.push(curCombo)
            //console.log("Played list"+JSON.stringify(playedList))
            playList.splice(randomNumber, 1);
            //console.log("After: Play list"+JSON.stringify(playList))

            //alert("play: "+curBtn)
            $('.btnMPL').each(function(i, obj) {
              $(obj).css("color","black")
            });

            $("#"+curBtn).css("color","white")
            $("#"+curBtn).css("background-color","green")
            $("#"+curchk).prop('checked', true);

            //filePath = documents[curAlbum].filePath
            //if(filePath.indexOf("Spanish")==0) {
            //  filePath = "/Albums/"+filePath
            //}
            
            //songTitle= documents[curAlbum].songs[curSong].Song[0].Title
            //trackPath = (rootpath + filePath + songTitle)
            console.log("trackPath: "+trackPath)
            player.setAttribute( 'src', trackPath)
            player.play()                
          });                                       
          //console.log("Normal Play mode")
          //console.log("listen.currentTime: " + listen.currentTime)     
        }        
        //listen.play(trackPath)      
        //playPause(trackPath)            
        //alert(documents.length)      
        $("#divControls").removeClass("hidden")   
        $("#divVolume").removeClass("hidden") 
        $("#divJukeNav").addClass("hidden")      
        $("#divKB").addClass("hidden")      
        $("#progress").removeClass("hidden")      
        $("#volume").removeClass("hidden")      
        //$("#divSelectArtists").addClass("hidden")      
        //$("#divSearchBy").addClass("hidden")      
      }

      const moveElement = (dir,px) => {        
        var curElement = $("#"+curEl.value)

        switch (dir) {
          case "up":
            curElement.css('position', 'absolute').css('top', '-='+px+'px');
            break;
          case "left":
            curElement.css('position', 'absolute').css('left', '-='+px+'px');
            break;
          case "right":
            curElement.css('position', 'absolute').css('left', '+='+px+'px');
            break;
          case "down":
            curElement.css('position', 'absolute').css('top', '+='+px+'px');
            break;
        } 
      }

      const showTypes = (e) => {        
        $(".navs").addClass("hidden")
        $("#divNav").removeClass("hidden")
        $("#divNav").removeClass("divNav")
        $("#divNav").addClass("divNavFull")
        $("#divNavTypes").removeClass("hidden")
        $(".divJukebox").addClass("shrinkJukebox")
        //$("#imgJukebox").addClass("hidden")        
        $("#divTopRow").addClass("hidden")
        curLevel.value = "Types"                
      }

      const btnTypeClicked = (i,type) => {
        //alert("btnTypeClicked")
        var curElementId = "divType"+type
        $(".navs").addClass("hidden")
        $(".types").css({'border':'solid black 1px'});
        $("#"+curElementId).css({'border':'solid gold 5px'});
        $("#divNavLanguages").removeClass("hidden")

        selectedType.value = i
        selType.value = type
        curLevel.value = "Languages"
        selType.value = "Albums" 
        selType2.value = "Albums" 
      }

      const btnLanguageClicked = (i,language) => {
        $(".navs").addClass("hidden")
        var curElementId = "divLanguage"+language
        $(".languages").css({'border':'solid black 1px'});
        $("#"+curElementId).css({'border':'solid gold 5px'});
        $("#divNavGenres").removeClass("hidden")

        selectedLanguage.value = i
        selLanguage.value = language
        curLevel.value = "Genres"        
      }

      const btnGenreClicked = (i,genre) => {
        $(".navs").addClass("hidden")
        var curElementId = "divGenre"+genre.replace(' ','').replace(' ','')
        $(".genres").css({'border':'solid black 1px'});
        $("#"+curElementId).css({'border':'solid gold 5px'});
        $("#divNavSubgenres").removeClass("hidden")

        selectedGenre.value = i
        selGenre.value = genre
        curLevel.value = "Subgenres"  
      }

      const btnSubgenreClicked = (i,subgenre) => {
        $(".navs").addClass("hidden")
        var curElementId = "divSubgenre"+subgenre
        $(".subgenres").css({'border':'solid black 1px'});
        $("#"+curElementId).css({'border':'solid gold 5px'});
        $("#divNavSubgenres").removeClass("hidden")

        selectedSubgenre.value = i
        selSubgenre.value = language
        curLevel.value = "Artists"  
      }

      const chooseNav = (i,type) => { 
        //alert("chooseNav")
        $(".navs").addClass("hidden")
        var curElementId = "divType"+type
        $(".types").css({'border':'solid black 1px'});
        $("#"+curElementId).css({'border':'solid gold 5px'});
        $("#divNavLanguages").removeClass("hidden")

        selectedType.value = i
        selType.value = type
        curLevel.value = "Languages"
        //selType.value = "Albums" 
        //selType2.value = "Albums" 

        //alert("type: "+type)
        //alert("ii: "+i)
        selType.value = type             
        selType2.value = type             
        //alert("index: "+index)
        //alert("chooseNav")
        //showLanguages(index,type,sortedData)
        //if(type=='') {
        //  type = "Albums"
        //}
        switch(type) {
          case "Albums":
            showLanguages(i)
            break;
          case "Singles":
            showLanguages(i)
            break;
          case "Playlists":
            showDJ(i)
            break;
          case "Youtubes":
            showYoutubes(i)
            break;
        }
        //handleClickPL(i,sortedData)        
      }

      const showLanguages = (i) => {     
        //selType2.value = type             
        //alert("type2: "+selType2.value)
        //selType2.value = type             
        //alert("i: "+i)
        //alert("selType2.value: "+selType2.value)

        //if(!isMobileFlag) {
          //$("#divAlbums").css("max-height", ((screen.height-document.querySelector('#divAlbums').getBoundingClientRect().top)-225))
        //}
          
        //showKB()
        //$("#divAlbums").removeClass("hidden")

        

        selectedType.value = i        
        
        
        /*
        selLanguage.value = ""             
        selGenre.value = ""             
        selSubgenre.value = ""             
        selArtist.value = ""
        selAlbum.value = ""             
        selSong.value = ""    
        */
        
        //$("#divAlbums").show()        
        
      }

      const btnPlayClicked = function() {        
        //alert("btnPlayClicked")                    
      }
                
      const btnBackClicked = function() {        
        //alert("btnBackClicked")          
      }
                
      return {  
        rootPath,selectedMember,selectedType,selectedLanguage,selectedGenre,selectedSubgenre,selectedArtist,selectedAlbum,selectedSong,selectedPlaylist,
        jukebox,currentUser,chooseNav,btnBackClicked,btnPlayClicked,documents,types,showTypes,btnTypeClicked,btnLanguageClicked,btnGenreClicked,
        btnSubgenreClicked,Test,toprow,pureColor, gradientColor,moveElement,moveElement,curEl,handleClickPL,selType2,selArtists,toggleDivNav
      }
    }
  }         
</script>

<style >  
  /* For Mobile Portrait View */
  @media screen
  and (min-device-width: 401px)
  and (orientation: landscape) {
    .divMain {  
      display: flex;
      margin: auto;
      justify-content: center;
      border: solid black 0px;
      width: 100%;
      z-index: 0;
      position: absolute;
      top: 60px;  
      transition-duration: 500ms;
    }
    
    .divMain:active {      
      transform: scale(1);
    }

    .divJukebox {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      border: solid red 0px;
      width: 100%;
      position: absolute;
      top: 40px;
      margin-left: 3px;
      transform : rotate(0deg);
    }
    
    .imgJukebox {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      border: solid blue 0px;        
      width: 950px;
    }

    .divWaveDancers {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      border: solid red 0px;      
      width: 100%;
      position: absolute;
      top: 1190px;
      margin-left: 4px;  
    }

    .imgWaveDancers {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      border: double 10px transparent;
      border-radius: 100%;
      background-image: linear-gradient(rgb(179, 63, 63), white), radial-gradient(circle at top left, rgb(255, 255, 255), #f7f5de);
      background-origin: border-box;
      background-clip: content-box, border-box;
      width: 115px;    
    }

    .divSearch {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      color: white;
      background-color: red;
      border: solid black 3px;
      padding-top: 7px;
      border-radius: 20px;      
      position: absolute;
      top: 423px;  
      margin-left: -229px;
      z-index: 1;
      font-size: 18px;
      font-weight: 700;
      width: 90px;
      height: 47px;
    }

    .divPlay {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      color: white;
      background-color: red;
      border: solid black 3px;
      padding-top: 9px;
      border-radius: 20px;      
      position: absolute;
      top: 417px;  
      margin-left: 226px;
      z-index: 1;
      font-size: 18px;
      font-weight: 700;
      width: 90px;
      height: 47px;
    }

    .divJookbox {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      border: solid red 0px;      
      width: 100%;
      position: absolute;
      top: 319px;  
      transform : rotate(-1.2deg);
    }

    .imgJookbox {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      padding: 2px;
      border: double 0px transparent;
      border-radius: 0px;
      background-image: linear-gradient(rgb(133, 114, 114), white), radial-gradient(circle at top left, rgb(68, 67, 67), #1d1d1c);
      background-origin: border-box;
      background-clip: content-box, border-box;
      width: 175px;    
    }
    
    .divControls {  
      display: flex;
      margin: auto;
      border: solid red 0px;      
      width: 100%;
      position: absolute;
      top: 625px;        
    }

    .imgControls {  
      display: flex;
      margin: auto;
      border: solid black 0px;    
      width: 650px;
      height: 845px;  
    }

    .divDisplay {  
      display: flex;
      margin: auto;
      border: solid red 0px;      
      width: 100%;
      position: absolute;
      top: 510px;        
    }

    .play:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: black;
    }

    .imgDisplay {  
      display: flex;
      margin: auto;
      border: solid black 0px;    
      width: 70%;
      height: 130px;  
    }    
    
    .divNav {            
      display: flex;
      margin: auto;
      justify-content: center;
      text-align: center;
      justify-items: center;
      flex-wrap: wrap;
      width: 60%;
      height:20%;
      border: solid purple 0px;
      position: absolute;
      top: 625px;
      z-index: 2;
    }  

    .divTopRow {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid black 0px;
      width: 85%;
      flex-wrap: wrap;
      position: absolute;
      top: 15px;
    }

    .toprow {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 205px;
      height: 60px;
      border-radius: 4px;
      box-shadow: 3px 3px 5px rgb(0, 0, 0);
      background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(255, 255, 255) 16.3%, rgb(212, 208, 208) 100.2% );      margin-bottom: 5px;
      margin-left: 0px;
      font-size: 18px;
      font-weight: 700;
      opacity: 1;
    }

    .divTopRow:hover {
      transform: scale(1.5);
    }

    .divNavTypes {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid white 0px;
      width: 55%;
      flex-wrap: nowrap;
      position: absolute;
      top: 90px;
    }

    .types {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 155px;
      height: 60px;
      border-radius: 4px;
      box-shadow: 3px 3px 5px rgb(0, 0, 0);
      background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(255, 255, 255) 16.3%, rgb(212, 208, 208) 100.2% );
      margin-bottom: 0px;
      margin-left: 0px;
      font-size: 18px;
      font-weight: 700;
      opacity: 1;
    }

    .types:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
    }
    
    .divNavLanguages {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid white 0px;
      width: 55%;
      flex-wrap: nowrap;
      position: absolute;
      top: 0px;
    }

    .languages {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 155px;
      height: 60px;
      border-radius: 4px;
      box-shadow: 3px 3px 5px rgb(0, 0, 0);
      background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(255, 255, 255) 16.3%, rgb(212, 208, 208) 100.2% );
      margin-bottom: 0px;
      margin-left: 0px;
      font-size: 18px;
      font-weight: 700;
      opacity: 1;
    }
    
    .languages:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
    }

    .divNavGenres {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid red 5px;
      width: 100%;
      flex-wrap: wrap;
      position: absolute;
      top: 90px;
    }

    .genres {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 155px;
      height: 60px;
      border-radius: 4px;
      box-shadow: 3px 3px 5px rgb(0, 0, 0);
      background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(255, 255, 255) 16.3%, rgb(212, 208, 208) 100.2% );
      margin-bottom: 0px;
      margin-left: 0px;
      font-size: 18px;
      font-weight: 700;
      opacity: 1;
    }
    
    .genres:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
    }

    .divNavSubgenres {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid white 0px;
      width: 55%;
      flex-wrap: nowrap;
      position: absolute;
      top: 90px;
    }

    .subgenres {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 155px;
      height: 60px;
      border-radius: 4px;
      box-shadow: 3px 3px 5px rgb(0, 0, 0);
      background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgb(255, 255, 255) 16.3%, rgb(212, 208, 208) 100.2% );
      margin-bottom: 0px;
      margin-left: 0px;
      font-size: 18px;
      font-weight: 700;
      opacity: 1;
    }
    
    .subgenres:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
    }
  }

  /* For Mobile Portrait View */
  @media screen
  and (max-device-width: 480px)
  and (orientation: portrait) {     
    .divMain {
      display: flex;
      margin: auto;
      justify-content: center;  
      border: solid black 0px;
      z-index: 0;
      position: absolute;
      top: 50px;
      width: 385px;  
      height: 660px;  
    }

    .divJukebox {  
      border: solid red 0px;
      top: 10px;  
      width: 100%;  
      height: 100%;  
      position: absolute;
      z-index: 1;    
    }

    .shrinkJukebox {  
      border: solid red 0px;
      width: 100%;
      transition: 1s;      
    }
    
    .imgJukebox {        
      border: solid blue 0px;        
      width: 93%;
    }

    .divNavFull {            
      display: flex;
      margin:  10px auto;
      justify-content: center;
      text-align: center;
      justify-items: center;
      flex-wrap: wrap;
      width: 100%;      
      height:650px;
      border-radius: 0px;
      transition: 1s;  
      z-index: 1;
      position: absolute;
      top: 0px;
      background-color: steelblue;
    }
    
    .divNav {            
      display: flex;
      margin: auto;
      justify-content: center;
      text-align: center;
      justify-items: center;
      flex-wrap: wrap;
      border: solid black 2px;      
      border-radius: 0px;
      transform: scale(.32);
      transition: 1s; 
      background-color: steelblue;
      position: absolute;
      top: 132px;
      left: -1px;
      height: 235px;
      z-index: 2;
    }

    .divTopRow {
      display: flex;
      margin:  0px auto;
      justify-content: center;
      border: solid black 0px;
      width: 100%;
      flex-wrap: nowrap;
    }

    .toprow {
      display: none;
      margin: auto;    
      justify-content: center;
      color: white;
      border: solid black 3px;
      border-radius: 10px;
      background-color: darkred;
      width: 230px;
      height: 60px;
      font-size: 50px;
      font-weight: 600;
    }

    .divNavTypes {
      display: flex;
      margin: auto;
      justify-content: center;
      border: solid black 0px;
      width: 100%;
      height: 100%;
      flex-wrap: nowrap;  
      margin-top: 5px;
    }

    .types {
      display: flex;
      margin: auto;    
      justify-content: center;
      width: 110px;
      height: 35px;
      border-radius: 4px;
      margin-top: 5px;
    }

    .types:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
    }
    
    .divNavLanguages {
      display: flex;
      margin: auto;
      justify-content: center;
      border: solid black 0px;
      width: 100%;
      flex-wrap: nowrap;      
      transition: 2s;    
      position: absolute;  
    }

    .languages {
      display: flex;
      margin: 5px auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 90px;
      height: 35px;
      border-radius: 4px;
      box-shadow: 0px 0px 0px rgb(0, 0, 0);
      background-color: white;
      margin-bottom: 0px;
      margin-right: 2px;
      font-size: 12px;
      font-weight: 700;
      flex-wrap: wrap;
      opacity: 1;  
    }
    
    .languages:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
    }

    .divNavGenres {
      display: flex;
      margin: auto;
      justify-content: center;
      border: solid black 0px;
      width: 100%;
      flex-wrap: wrap;
      margin-bottom: 0px;
      padding-bottom: 2px;
      position: absolute;  
    }

    .genres {
      display: flex;
      margin: auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 90px;
      height: 35px;
      border-radius: 4px;
      box-shadow: 0px 0px 0px rgb(0, 0, 0);
      background-color: white;
      margin-bottom: 5px;
      margin-right: 2px;
      font-size: 12px;
      font-weight: 700;
      flex-wrap: wrap;
      opacity: 1;    
    }
    
    .genres:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
      margin-top: 30px;
    }

    .divNavSubgenres {
      display: flex;
      margin: auto;
      justify-content: center;
      border: solid black 0px;
      width: 100%;
      flex-wrap: wrap;
      padding-bottom: 2px;
      position: absolute;  
    }

    .subgenres {
      display: flex;
      margin: 5px auto;    
      justify-content: center;
      border: solid black 2px;
      color: black;
      width: 90px;
      height: 35px;
      border-radius: 4px;
      box-shadow: 0px 0px 0px rgb(0, 0, 0);
      background-color: white;
      margin-bottom: 5px;
      margin-right: 2px;
      font-size: 12px;
      font-weight: 700;
      flex-wrap: wrap;
      opacity: 1;   
    }
    
    .subgenres:hover {
      -ms-transform: scale(.95); /* IE 9 */
      -webkit-transform: scale(.95); /* Safari 3-8 */
      transform: scale(1.00);         
      color: blue;
    }
  }

  .divSearch {  
      display: inline-flex;
      margin: auto;
      justify-content: center;
      color: black;
      background-color: transparent;
      border: solid black 0px;
      padding-top: 0px;
      border-radius: 4px;      
      position: absolute;
      top: 340px;  
      margin-left: -170px;
      z-index: 1;
      font-size: 9px;
      font-weight: 100;
      width: 30px;
      height: 15px;
  }

  .divPlay {  
    display: inline-flex;
    margin: auto;
    justify-content: center;
    color: white;
    background-color: red;
    border: solid black 3px;
    padding-top: 7px;
    border-radius: 20px;      
    position: absolute;
    top: 340px;  
    margin-left: 175px;
    z-index: 1;
    font-size: 18px;
    font-weight: 700;
    width: 30px;
    height: 15px;
  }

  .divWaveDancers {  
    display: flex;
    margin: auto;
    border: solid red 0px;      
    width: 100%;
    position: absolute;
    top: 460px;  
    left: 1px;  
  }

  .imgWaveDancers {  
    display: flex;
    margin: auto;
    border: solid rgb(245, 245, 241) 1px;    
    border-radius: 100%;      
    width: 12%;
  }
  
  .divControls {  
    display: flex;
    margin: auto;
    border: solid red 0px;      
    width: 100%;
    position: absolute;
    top: 270px;        
  }

  .imgControls {  
    display: flex;
    margin: auto;
    border: solid black 3px;    
    width: 65%;
    height: 330px;  
  }

  .divDisplay {  
    display: flex;
    margin: auto;
    border: solid red 0px;      
    width: 100%;
    position: absolute;
    top: 510px;        
  }

  .imgDisplay {  
    display: flex;
    margin: auto;
    border: solid black 7px;    
    width: 70%;
    height: 130px;  
  }  
  
  .play:hover {
    -ms-transform: scale(.95); /* IE 9 */
    -webkit-transform: scale(.95); /* Safari 3-8 */
    transform: scale(1.00);         
    color: black;
  }

</style>
    
    