<template>
  <div class="content" style="margin-top: 0px;">
      <router-view/>
      <lis/>

  </div>   
</template>

<script>
  import { ref } from 'vue';
  import { useToast } from 'primevue/usetoast';
  import { router } from 'vue-router';
  import Navbar from './components/Navbar.vue'
  import { rootPath  } from './assets/json/rootPath.json'
  import { ColorPicker } from "vue3-colorpicker";
  import "vue3-colorpicker/style.css";

  export default {
    components: { ColorPicker, Navbar},
    setup() {       
        return { }
    }    
  }
  
</script>